<template>
    <div id="home" class="page-wrapper page-home">
        <!-- Hero Condos -->
        <section id="hero-condos" class="hero-condos extends quartier hero-transition-group">
            <div class="inner site-max-width">
                <h3 class="top-label label" data-inview="fadeInUp" data-delay="200">
                    {{ contentIsLoaded ? content.heroCondos.titre : '' }}
                </h3>
                <h1 class="big-title title" data-inview="fadeInUp" data-delay="1000">
                    <span v-html="contentIsLoaded ? content.heroCondos.sousTitre : '&nbsp;'"></span>

                    <span class="big-text float-text"> {{ contentIsLoaded ? content.heroCondos.texte : '' }} </span>
                </h1>
            </div>
            <div class="grid-images-dynamic" quartier-zoomout>
                <img class="size" :src="contentIsLoaded ? content.heroCondos.image1.url : '/static-imgs/quartier.jpg'" :alt="contentIsLoaded ? content.heroCondos.image1.titre : ''" />

                <img
                    class="main"
                    :src="contentIsLoaded ? content.heroCondos.image1.url : '/static-imgs/quartier.jpg'"
                    :alt="contentIsLoaded ? content.heroCondos.image1.titre : ''"
                    quartier-zoomout-left
                />

                <img
                    class="secondary-top"
                    :src="contentIsLoaded ? content.heroCondos.image2.url : '/static-imgs/cinema.jpg'"
                    :alt="contentIsLoaded ? content.heroCondos.image2.titre : ''"
                    quartier-zoomout-top
                />
                <img
                    class="secondary-bottom"
                    :src="contentIsLoaded ? content.heroCondos.image3.url : '/static-imgs/life.jpg'"
                    :alt="contentIsLoaded ? content.heroCondos.image3.titre : ''"
                    quartier-zoomout-bottom
                />

                <img
                    class="last"
                    :src="contentIsLoaded ? content.heroCondos.image4.url : '/static-imgs/velo.jpg'"
                    :alt="contentIsLoaded ? content.heroCondos.image4.titre : ''"
                    quartier-zoomout-right
                />
            </div>
            <div class="site-max-width">
                <p class="regular-text lefty-bloc-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="contentIsLoaded ? content.heroCondos.texteFin : '&nbsp;'"></span>
                </p>

                <SealDurable class="seal" condo-rotate />
            </div>
        </section>

        <iframe v-if="$route.meta.lang === 'fr'" id="planpoint" frameBorder="0" allowFullScreen style="width: 100%;" src="https://app.planpoint.io/LOMBOI/le-lomboi?lang=fr&style=Style%201"></iframe>
        <iframe v-else id="planpoint" frameBorder="0" allowFullScreen style="width: 100%;" src="https://app.planpoint.io/LOMBOI/le-lomboi?lang=English&style=Style%201"></iframe>

        <!-- Form -->
        <BottomForm :formLink="globalsIsLoaded ? globals.footer.formulaireInscription.lienUrl[$route.meta.lang] : ''" :formTitle="$t('Découvrez <br /> le projet')" />
    </div>
</template>

<script>
// @ is an alias to /src
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

import { mapState, mapGetters, mapActions } from 'vuex'
import SealDurable from '@/components/svgs/SealDurable'
import BottomForm from '@/components/BottomForm'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'Condos',

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ]
        }
    },

    components: {
        SealDurable,
        BottomForm,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.startHomeHeroAnimations()

                        setTimeout(() => {
                            initInViewAnimations()
                            this.initPageAnimations()
                            document.querySelector('#app').classList.add('is-loaded')
                        }, this.initAnimsDelay1)

                        window.addEventListener('message', (event) => {
                            const [intendedHeight] = event.data.split('x');
                            const iframe = document.querySelector('iframe#planpoint');
                            const h = parseInt(intendedHeight);
                            // const w = parseInt(intendedWidth);
                            iframe.height = Math.max(h, window.innerHeight);
                        });

                        setInterval(() => {
                            document.querySelector('#planpoint').contentWindow.postMessage('get_size', '*');
                        }, 1000)
                    }, 475)

                    setTimeout(() => {
                        document.querySelector('.site-loader').classList.add('is-loaded')
                        document.querySelector('.page-wrapper').classList.add('is-loaded')
                        this.setAnimsDelays(55)
                    }, this.initAnimsDelay2)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        ...mapActions(['setAnimsDelays']),
        smoothScrollTo(selector) {
            setTimeout(() => {
                gsap.to(window, { duration: 2, scrollTo: selector })
            }, 175)
        },
        initPageAnimations() {
            gsap.to('[quartier-zoomout-left]', {
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                duration: 1.7175,
                delay: 1.5375,
                ease: 'power2.inOut',
            })

            gsap.to('[quartier-zoomout-top]', {
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                duration: 1.7175,
                delay: 1.5375,
                ease: 'power2.inOut',
            })

            gsap.to('[quartier-zoomout-bottom]', {
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                duration: 1.7175,
                delay: 1.5375,
                ease: 'power2.inOut',
            })

            gsap.to('[quartier-zoomout-right]', {
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                duration: 1.7175,
                delay: 1.5375,
                ease: 'power2.inOut',
            })

            gsap.fromTo(
                '[condo-rotate]',
                {
                    rotate: 0,
                },
                {
                    rotation: 120,
                    duration: 48,
                    repeat: -1,
                    yoyo: true,
                    ease: 'none',
                }
            )
        },
    },
}
</script>
